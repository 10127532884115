<template>
  <div>
    <v-card v-if="isLoaded"
        class="mx-auto my-5" elevation="3" :width="$vuetify.breakpoint.mobile ? '90%' : '50%'">
      <v-card-title class="d-flex justify-center">
        <span>PWA Setting</span>
      </v-card-title>
      <v-tabs grow>
        <v-tab class="mx-auto" v-for="tab in tabs" :key="tab.title">
          {{ tab.title }}
        </v-tab>
        <v-tab-item>
            <v-card class = "ma-auto mt-4" width="80%" elevation="0">
              <v-alert icon="mdi-alert-circle-outline" outlined color="info">You can check your udpate at
                <v-icon color="red">mdi-heart</v-icon> On the upper right after <v-icon color="secondary">mdi-reload</v-icon>.
              </v-alert>
              <v-form ref="appform">
                <v-switch @change="switchChange" v-model= "appinfo.active" inset color="primary" :loading="tabs[0].switchLoading"
                      :label="`Turn ${appinfo.active? 'OFF':'ON'} Your PWA`">
                </v-switch>
                <NameField label="Name of Your PWA" :value.sync="appinfo.name" :isApplicable="appinfo.active"
                  hint="Your PWA's main name"></NameField>
                <NameField label="Short Name" :value.sync="appinfo.short_name" :isApplicable="appinfo.active"
                  hint="PWA's short name in Smartphones' Home Page "></NameField>
                <v-textarea solo v-model="appinfo.description"
                  placeholder="Write something about how your PWA works with your members!" v-if="appinfo.active">
                </v-textarea>
                <v-row v-if="appinfo.active">
                  <v-btn class="ma-auto mb-4" large color="primary" :loading="tabs[0].btnLoading"
                    @click="updateAppDetails('appform')">
                    Apply
                  </v-btn>
                </v-row>
              </v-form>
                <v-row justify="center" v-if="tabs[0].message.displayed">
                  <span :class="`mb-7 text-body-3 text-center ${tabs[0].message.color}--text`">
                    <v-icon small :color="tabs[0].message.color">mdi-check-circle</v-icon>
                    {{ tabs[0].message.text }}
                  </span>
                </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-alert class= "my-7" icon="mdi-card-search-outline" outlined color="primary">
              > If you'r having such an issue of getting pictures with suitable sizes, search an online tool such as
              <a href="https://realfavicongenerator.net/" target="_blank">RealFavicon Generator</a>. <br />
              > Once you have completed any updates. It may take 24 hours to reflect this.
              See <a href = "https://web.dev/manifest-updates/#cr-android">Chrome for details</a>. For iOS users,
              they'll need to reinstall (a few seconds) the PWA to reflect the change for current moment.<br />
              > All the Best!
            </v-alert>
            <v-card elevation="0" v-for="(imageType, n) in displayImagesTypes" :key="imageType.title" class="mx-auto my-5"
                width="$vuetify.breakpoint.mobile ? '50%' : '30%'">
              <v-card-title>{{imageType.title}}</v-card-title>
              <v-alert class = "mx-auto" width="80%" icon="mdi-alert-circle-outline" outlined color="info">
                {{imageType.info}}
              </v-alert>
              <v-card-text>
              <v-carousel  hide-delimiter-background height="auto">
                <v-carousel-item v-for="(image,n) in imageType.data" :key="n"
                    :src="image.src">
                </v-carousel-item>
              </v-carousel>
              <v-file-input class= "my-3" solo chips clearable show-size :multiple="imageType.multiple" small-chips
                  @change="updateImages(n)"
                  placeholder="Click to upload"  truncate-length="10" accept="image/*"
                  v-model="tabs[1].uploadObjectTypes[n]">
              </v-file-input>
              </v-card-text>
            </v-card>
            <v-row justify="center" class="mt-n7 mb-7">
              <v-btn large color="primary" @click="openUploadDialog" >
                  Upload</v-btn>
            </v-row>
            <v-dialog :persistent="tabs[1].btnLoading" v-model="tabs[1].message.displayed" max-width="80%" >
              <v-card class="pa-8">
              <v-row justify="center" class="my-2">
                <v-icon x-large :color="tabs[1].message.color" class="mx-auto" >mdi-cloud-upload-outline</v-icon>
              </v-row>
              <v-row justify="center" class="text-center">
                {{tabs[1].message.text}}
              </v-row>
              <v-row justify="center" class="my-7">
                <v-btn :color="tabs[1].message.color" v-if="tabs[1].message.color==='info'"
                  @click="uploadImages" :loading="tabs[1].btnLoading">
                  CONFIRM
                </v-btn>
                <v-btn :color="tabs[1].message.color" v-else-if="tabs[1].message.color==='success'"
                  @click="tabs[1].message.displayed = false" :loading="tabs[1].btnLoading">
                  DONE
                </v-btn>
                <v-btn :color="tabs[1].message.color" v-else
                  @click="tabs[1].message.displayed = false" :loading="tabs[1].btnLoading">
                  OK
                </v-btn>
              </v-row>
              </v-card>
            </v-dialog>
          </v-tab-item>
      </v-tabs>
    </v-card>
    <div v-else>
    <v-skeleton-loader type="card-avatar, list-item"  class = "mx-auto my-10"
      max-height="60vh" max-width="60vw"></v-skeleton-loader>
  </div>

  </div>
</template>
<script>
import Vue from 'vue'
import NameField from '@/components/fields/NameField.vue'
import { mapGetters } from 'vuex'
import router from '@/router'
export default {
  components: {
    NameField
  },
  data () {
    return {
      isLoaded: false,
      tabs: [
        {
          title: 'PWA details',
          btnLoading: false,
          switchLoading: false,
          message: {
            text: '',
            displayed: false,
            color: ''
          }
        },
        {
          title: 'Icons & Photos',
          btnLoading: false,
          uploadObjectTypes: [],
          message: {
            text: '',
            displayed: false,
            color: ''
          }
        }
      ],
      // data from backend service
      appinfo: {},
      displayImagesTypes: []
    }
  },
  computed: {
    ...mapGetters('appStore', ['getPwaDetails']),
    ...mapGetters('userStore', ['loginConfirm'])
  },
  methods: {
    switchChange () {
      const isOn = this.appinfo.active
      this.tabs[0].switchLoading = 'primary'
      // console.log('Turn your PWA: ', isOn)
      Vue.$privateApi.put('/pwa/active',
        {
          active: isOn
        }).then(() => {
        // console.log(`Set PWA Active : ${isOn} Successfully`)
        this.tabs[0].message.text = `You have successfully set Your PWA ${isOn ? 'ON' : 'OFF'}.`
        this.tabs[0].message.color = 'success'
      }).catch(() => {
        // console.log(`Setting PWA Active : ${isOn} Fails`)
        this.tabs[0].message.text = `Fails to set Your PWA ${isOn ? 'ON' : 'OFF'}. Please try later.`
        this.tabs[0].message.color = 'error'
      }).finally(() => {
        this.tabs[0].switchLoading = false
      })
    },
    updateAppDetails (form) {
      if (this.$refs[form].validate()) {
        this.tabs[0].btnLoading = true
        // console.log(this.appinfo)
        Vue.$privateApi
          .put('/pwa/updateappinfo', this.appinfo)
          .then(() => {
            // console.log('Update App Info Successfully')
            this.tabs[0].message.text = 'You have successfully updated your PWA details.'
            this.tabs[0].message.color = 'success'
          })
          .catch(() => {
            // console.log('Fail to update App Info')
            this.tabs[0].message.text = 'Fails to update your PWA details. Please try later.'
            this.tabs[0].message.color = 'error'
          }).finally(() => {
            this.tabs[0].btnLoading = false
            this.tabs[0].message.displayed = true
          })
      }
    },
    openUploadDialog () {
      this.tabs[1].message.displayed = true
      this.tabs[1].message.text = 'Confirm to upload the files?'
      this.tabs[1].message.color = 'info'
    },
    /**
     * When user pick image(s) from local file system, the UI will detect this
     *  and update the image(s) to the courasel for preview purpose
     */
    updateImages (index) {
      const uploadObjects = this.tabs[1].uploadObjectTypes[index]
      // console.log(uploadObjects)
      const images = this.displayImagesTypes[index].data
      uploadObjects.forEach((file, j) => {
        if (images[j] && images[j] !== undefined) {
          images[j].src = URL.createObjectURL(file)
        } else {
          images[j] = {}
          images[j].src = URL.createObjectURL(file)
          images[j].id = null
        }
      })
    },
    uploadImages () {
      let error = false
      this.tabs[1].btnLoading = true
      const image = new FormData()
      this.tabs[1].uploadObjectTypes.forEach((uploadObjectType, j) => {
        if (uploadObjectType.length <= 3 && uploadObjectType.length >= 1) {
          // j: 0 = 192x192, 1 = 512x512, 2 = apple-touch-icon, 3 = photos
          const originalImages = this.displayImagesTypes[j].data
          uploadObjectType.forEach((file, i) => {
            // console.log(file)
            if (file && file.size <= (4 * 1048576) && file.type.includes('image')) {
              if (j <= 1) {
                image.append(originalImages[i].sizes, file)
                // console.log(image)
                // console.log(image)
              } else if (j === 2) {
                image.append('apple', file)
              } else {
                image.append(`photo:${originalImages[i].id}`, file)
              }
            } else {
              error = true
            }
          })
        } else {
          error = true
        }
      })
      if (error) {
        this.tabs[1].message.text = 'Max 3 Photos and 4MB for each.'
        this.tabs[1].message.color = 'error'
        this.tabs[1].message.displayed = true
        this.tabs[1].btnLoading = false
        return
      }
      // console.log(image)
      Vue.$privateApi.post('/pwa/files', image,
        {
          headers: { 'Content-Type': 'multi-part/form-data' }
        }).then((response) => {
        this.tabs[1].message.text = 'You have succesfully updated your images.'
        this.tabs[1].message.color = 'success'
        this.tabs[1].message.displayed = true
        // console.log(response)
      }).catch((err) => {
        console.error(`Fails to upload the images: ${err.response.data} (${err.response.status})`)
        if (navigator.onLine) {
          this.tabs[1].message.text = 'Oops...Something goes wrong at Server. Please try later.'
        } else {
          this.tabs[1].message.text = 'You lose your connection!'
        }
        this.tabs[1].message.color = 'error'
        this.tabs[1].message.displayed = true
        this.tabs[1].btnLoading = false
      }).finally(() => {
        this.tabs[1].btnLoading = false
      })
    },
    loadAllResources () {
      Vue.$privateApi.get('/pwa/initpwa').then((response) => {
        const data = response.data
        this.appinfo = data.appinfo
        data.icons.forEach((icon, i) => {
          this.displayImagesTypes[i] = {
            title: `${icon.sizes} PWA Icon`,
            multiple: false,
            info: `The PWA Home Page icon with ${icon.sizes} Dimension for Android Devices`,
            data: [icon]
          }
        })
      }).then(() => {
        const charity = Vue.$keycloak.tokenParsed.email
        const appleIconRequest = Vue.$originApi.get(`/${charity}/apple-touch-icon`)
          .then((response) => {
            this.displayImagesTypes[2] = {}
            this.displayImagesTypes[2].data = [{ src: response.data }]
          }).catch(() => {
            this.displayImagesTypes[2] = {}
            this.displayImagesTypes[2].data = [{ src: null }]
            console.error(`${charity} hasn't set apple-touch-icon for its PWA`)
          }).finally(() => {
            this.displayImagesTypes[2].title = 'Apple Touch Icon'
            this.displayImagesTypes[2].multiple = false
            this.displayImagesTypes[2].info = 'The PWA Home Page icon with 180x180 ' +
          'Dimension used specifically for iOS Devices'
            // console.log(this.displayImagesTypes[2])
          })
        const photosRequest = Vue.$publicApi.get('/pwa/pwaphotos', {
          params: { charity: charity }
        })
          .then((response) => {
            this.displayImagesTypes[3] = {}
            this.displayImagesTypes[3].data = response.data
          }).catch(() => {
            this.displayImagesTypes[3] = {}
            this.displayImagesTypes[3].data = [{ id: null, src: null }]
            console.error(`${charity} hasn't set photos for its PWA`)
          }).finally(() => {
            this.displayImagesTypes[3].title = 'Max 3 Photo(s)'
            this.displayImagesTypes[3].multiple = true
            this.displayImagesTypes[3].info = 'Photos about Your organisations.' +
          'These will be displayed in the Installation Dashboard'
            // console.log(this.displayImagesTypes[3])
          })
        Promise.all([photosRequest, appleIconRequest]).then(() => {
          this.isLoaded = true
        })
      })
    },
    routeToError () {
      const firstSeg = Vue.$pathConverter.getFirstSegment()
      router.push({
        name: 'Error',
        params: { root: firstSeg },
        query: { 401: window.location.href }
      })
    }
  },
  watch: {
    loginConfirm (newloginConfirm) {
      // in subsequent change in loginConfirm
      // when loginConfirm is confirmed, it is either true or false
      // enter if true and reroute to error page if false
      if (newloginConfirm) {
        this.loadAllResources()
      } else {
        this.routeToError()
      }
    }
  },
  created () {
    // if loginConfirm has been already set, load it or error page
    // if not, leave it to watcher function for subsequent update
    let token = null
    if (this.loginConfirm) {
      token = Vue.$keycloak.tokenParsed
      if (token.groups.includes('charity')) {
        this.loadAllResources()
      }
    }
    if (this.loginConfirm === false) {
      this.routeToError()
    } else {
      this.isLoaded = false
    }
  }
}
</script>
